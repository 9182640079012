import axios from 'axios'
const url = 'https://api.tavguvenlik.com'
const language = "5988516e10335356fbe21fb7"
//const languageEN = "5988516210335356fbe21fb6"
const token = '3f1c945dda80e43cd5683c8443092787'

const apiClient2 = axios.create({
    baseURL: url,
    withCredentials: false,
    headers: {
        'Authorization': 'Token ' + token,
        'Content-Type': 'application/json'
    }
})

export default {  
    
    getFooter(application, language) {
        return apiClient2.get(
            '/dynamicFields?key=' + 'footer' + '&application=' + application + '&language=' + language + '&isActive=true'
        );
    },  
    getNavigation(application, language) {
        return apiClient2.get(
            '/dynamicFields?key=' + 'category' + '&application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getLinesOfActivity(application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'fields-of-activities' + '?application=' + application + '&language=' + language + '&orderBy=order&isActive=true'   
        );
    },
    getCategorizedContent(application, language, slug) {
        return apiClient2.get(
            '/staticpages/categorized/' + slug + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getNews(application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'latestnews' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getAnnouncements(application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'publications' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getTAVSecurityInPress(application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'tsinpress' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getFrequentlyAsked(application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'faq' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getMainSlider(application, language) {
        return apiClient2.get(
            '/sliders' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getReferences(application) {
        return apiClient2.get(
            '/staticpages/categorized/' + 'references' + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    },
    getPageDetail(pagedetailslug, application, language) {
        return apiClient2.get(
            '/staticpages/categorized/' + pagedetailslug + '?application=' + application + '&language=' + language + '&isActive=true'
        );
    }
}

     










       // const token = '3f1c945dda80e43cd5683c8443092787'
       // const url = 'http://api.tavsecurity.tavitlab.com/dynamicFields?key=footer&application=5e5d10f0059b14822c6f256e&language=5988516e10335356fbe21fb7'

       // axios.get(url, {
        //    headers: {
        //    'Authorization': 'Token ' + token,
         //   'Content-Type': 'application/json'
         //   }
        //})
        //.then(res => this.footerItems = res.data.data.map((footerItem) => {
        //    return {id: footerItem.subDynamicFields._id, title: footerItem.subDynamicFields.value, link: footerItem.subDynamicFields.link}
        //}))
        //.then(res => console.log(res.data.data.subDynamicFields))
        //.then(res => this.footerItems =res.data.data.subDynamicFields)
        //.catch(error => console.log('There was an error:' + error))   
        
   // }