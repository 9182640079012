<template>
    <div id="News" class="col-lg-4">
        <div class="section">
            <div class="header-container">
                <h2>{{ $t('news')}}</h2>
                <p><a :href="$t('newsLink')">
                    {{ $t('allNews') }}
                  </a></p>
            </div>
            <div class="d-flex align-items-center item" v-for="newsItem of newsItems.slice(0, 3)" v-bind:key="newsItem._id">
                <div class="image-container">
                    <a :href="$t('newsLink')">
                        <img  v-if="newsItem.listImage" :src="'https://webcmstavsecurity.tav.aero/uploads/5e5d10f0059b14822c6f256e/static-pages/list-images/' + newsItem.listImage" alt="" width="80" height="65">
                        <img v-else src="@/assets/img/ph-news.jpg" alt="" width="80" height="65">                        
                    </a>
                </div>
                <div class="ml-3">
                    <p class="size-12 gray">{{newsItem.contentDate | formatDate}}</p>
                    <p class="blue semibold"><a :href="$t('newsLink')">{{ newsItem.title }}</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentService from "@/services/ContentService.js";

export default {
data () {
    return {
        newsItems: []
    }
},

created () {

    ContentService.getNews(this.$application, this.$t("languageCode"))
      .then(res => this.newsItems = res.data.data)
      .catch(error => console.log('There was an error:' + error)) 
        
    }
}
  
</script>

<style>
</style>