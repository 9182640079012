<template>
  <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages"><div>        
        
            <h1 class="main-title">{{ $t("publications") }}</h1>

            <div role="tablist">
              <b-card
                no-body
                class="mb-1"
                
                v-for="newsItem in newsItems.slice(
                  15 * (currentPage - 1),
                  15 * currentPage
                )"
                v-bind:key="newsItem._id"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    href="#"
                    v-b-toggle="'news-' + newsItem._id"
                    class="d-flex justify-content-between"
                  >
                    <span class="text-left">{{ newsItem.title }}</span>
                    <span class="when-opened">
                      <font-awesome-icon :icon="['fas', 'chevron-up']" />
                    </span>
                    <span class="when-closed">
                      <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'news-' + newsItem._id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text v-html="newsItem.description.replace('/files/', 'https://webcmstavsecurity.tav.aero/files/')"> </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                class="justify-content-center m-3"
              ></b-pagination>
           
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <Footer></Footer>
  </div>
</template>

<script>
import ContentService from "@/services/ContentService.js";

import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import Footer from "@/components/shared/Footer.vue";

export default {
  data() {
    return {
      newsItems: [],
      isVisible: true,
      perPage: 15,
      currentPage: 1,
      pageSlug: this.$route.params.slug
    };
  },
   components: {
    NavBar,
    Breadcrumb,
    SideBar,
    Footer
  },
   props: {
    slug: {
      type: String,
      required: true,
    }
  },
  created() {
    ContentService.getAnnouncements(this.$application, this.$t("languageCode"))
      .then((res) => (this.newsItems = res.data.data))
      .catch((error) => console.log("There was an error:" + error));
  },
  computed: {
    rows() {
      return this.newsItems.length;
    },
  },
};

// https://stackoverflow.com/questions/55978466/bootstrap-vue-b-pagination-component-not-changing-pages-on-click
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.content {
  background: #fff;
}

.card {
  background: transparent;
  border: none;
}

.card-header {
  background-color: transparent;
  border-bottom: 0.04rem solid #00357e;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

.card-body {
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  line-height: 1.5rem;
  letter-spacing: 0.015rem;
  font-weight: 300;
}

.btn,
.btn:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background: transparent;
  color: #00357e;
  border: none;
  box-shadow: none;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  line-height: 1.5rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
}
</style>
