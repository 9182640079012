<template>
  <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="`/${$i18n.locale}`" active-class="active" exact>
            <a>{{ $t("home") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
           <span v-for="navItem in navItems" v-bind:key="navItem.slug">
           <span v-if="navItem.slug == pageSlug">
            <a>{{ navItem.value }}</a>
             </span>
             </span>
        </li>
      </ol>
    </div>
  </nav>
</template>

<script>
import ContentService from "@/services/ContentService.js";
export default {
  data() {
    return {
      navItems: [],
      pageSlug: this.$route.params.slug
    };
  },
  created() {
    ContentService.getNavigation(this.$application, this.$t("languageCode"))
      .then((res) => (this.navItems = res.data.data.subDynamicFields))
      .catch((error) => console.log("There was an error:" + error));
  }

};
</script>
