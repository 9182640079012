<template>
  <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages">
            <div>
              <section id="ContactForm" class="section">
                <div class="container">
                  <h1 class="main-title">{{ $t("jobApplication") }}</h1>
                  <b-alert v-if="isSubmitted" show variant="success">{{ $t("yourApplicationHasBeenSent") }}</b-alert>
                  <form ref="contactUsForm" @submit.prevent="submitted" autocomplete="off">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="userName">{{ $t('jobAppFormLabels.name') }}
                            *</label>
                          <input type="text" id="userName" class="form-control col-sm-8" v-model="userData.userName"
                            :class="{ errror: $v.userData.userName.$error }" @blur="$v.userData.userName.$touch()">
                        </div>
                        <div v-if="$v.userData.userName.$error">
                          <p v-if="!$v.userData.userName.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.userNameRequired')
                          }}</p>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="userSurname">{{
                            $t('jobAppFormLabels.surname')
                          }} *</label>
                          <input type="text" id="userSurname" class="form-control col-sm-8" v-model="userData.userSurname"
                            :class="{ errror: $v.userData.userSurname.$error }" @blur="$v.userData.userSurname.$touch()">
                        </div>
                        <div v-if="$v.userData.userSurname.$error">
                          <p v-if="!$v.userData.userSurname.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.userSurameRequired')
                          }}</p>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="identificationNumber">{{
                            $t('jobAppFormLabels.identificationNumber') }}</label>
                          <input type="text" id="identificationNumber" class="form-control col-sm-8"
                            v-model="userData.identificationNumber"
                            :class="{ error: $v.userData.identificationNumber.$error }"
                            @blur="$v.userData.identificationNumber.$touch()">
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.gender') }} *</label>
                          <div class="col-sm-8">
                            <label class="radio-inline">
                              <input type="radio" v-model="userData.gender" value="Male"> {{
                                $t('jobAppFormLabels.genderOption.genderOption_1') }}
                            </label>
                            <label class="radio-inline">
                              <input type="radio" v-model="userData.gender" value="Female"> {{
                                $t('jobAppFormLabels.genderOption.genderOption_2') }}
                            </label>
                          </div>
                        </div>
                        <div v-if="$v.userData.gender.$error">
                          <p v-if="!$v.userData.gender.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.genderRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="dateOfBirth">{{
                            $t('contactFormLabels.birthDate')
                          }} *</label>
                          <input type="date" id="dateOfBirth" class="form-control col-sm-8" v-model="userData.dateOfBirth"
                            :class="{ errror: $v.userData.dateOfBirth.$error }" @blur="$v.userData.dateOfBirth.$touch()">
                        </div>
                        <div v-if="$v.userData.dateOfBirth.$error">
                          <p v-if="!$v.userData.dateOfBirth.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.birthDateRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="maritalStatus">{{
                            $t('jobAppFormLabels.maritalStatus') }} *</label>
                          <select id="maritalStatus" class="form-control col-sm-8" v-model="userData.maritalStatus"
                            :class="{ error: $v.userData.maritalStatus.$error }"
                            @blur="$v.userData.maritalStatus.$touch()">
                            <option value="Married">{{ $t('jobAppFormLabels.maritalStatusOption.maritalStatusOption_1') }}
                            </option>
                            <option value="Single">{{ $t('jobAppFormLabels.maritalStatusOption.maritalStatusOption_2') }}
                            </option>
                          </select>
                        </div>
                        <div v-if="$v.userData.maritalStatus.$error">
                          <p v-if="!$v.userData.maritalStatus.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.maritalStatusRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="education">{{ $t("education") }} *</label>
                          <select id="education" class="form-control col-sm-8" v-model="userData.education"
                            :class="{ errror: $v.userData.education.$error }" @blur="$v.userData.education.$touch()">
                            <option>{{ $t("jobAppFormLabels.education.education_1") }}</option>
                            <option>{{ $t("jobAppFormLabels.education.education_2") }}</option>
                            <option>{{ $t("jobAppFormLabels.education.education_3") }}</option>
                            <option>{{ $t("jobAppFormLabels.education.education_4") }}</option>
                          </select>
                        </div>
                        <div v-if="$v.userData.education.$error">
                          <p v-if="!$v.userData.education.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.educationRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.privateSecurityTraining') }}
                            *</label>

                          <select class="form-control col-sm-8" v-model="userData.securityTraining"
                            :class="{ error: $v.userData.securityTraining.$error }"
                            @blur="$v.userData.securityTraining.$touch()">
                            <option value="Armed">{{
                              $t('jobAppFormLabels.privateSecurityTrainingOption.privateSecurityTrainingOption_1') }}
                            </option>
                            <option value="Unarmed">{{
                              $t('jobAppFormLabels.privateSecurityTrainingOption.privateSecurityTrainingOption_2') }}
                            </option>
                            <option value="NotTaken">{{
                              $t("jobAppFormLabels.privateSecurityTrainingOption.privateSecurityTrainingOption_3") }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group row" v-if="['Armed', 'Unarmed'].includes(userData.securityTraining)">
                          <label class="col-sm-4 col-form-label blue" for="validityDate">{{
                            $t('jobAppFormLabels.validityDate') }}</label>

                          <input id="validityDate" type="date" class="form-control col-sm-8"
                            v-model="userData.validityDate" :class="{ error: $v.userData.validityDate.$error }"
                            @blur="$v.userData.validityDate.$touch()">

                        </div>

                        <div v-if="$v.userData.securityTraining.$error">
                          <p v-if="!$v.userData.securityTraining.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.privateSecurityTrainingRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.foreignLanguage') }}</label>

                          <!-- Language selection -->
                          <select class="form-control col-sm-8" v-model="userData.currentLanguage">
                            <option value="english">{{
                              $t('jobAppFormLabels.foreignLanguageOption.english') }}</option>
                            <option value="arabic">{{ $t('jobAppFormLabels.foreignLanguageOption.arabic')
                            }}</option>
                            <option value="french">{{ $t('jobAppFormLabels.foreignLanguageOption.french')
                            }}</option>
                            <option value="persian">{{
                              $t('jobAppFormLabels.foreignLanguageOption.persian') }}</option>
                            <option value="russian">{{
                              $t('jobAppFormLabels.foreignLanguageOption.russian') }}</option>
                            <option value="other">{{ $t('jobAppFormLabels.foreignLanguageOption.other')
                            }}</option>
                          </select>
                        </div>

                        <div class="form-group row" :class="{ 'd-none': userData.currentLanguage === 'other' }">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.level')
                          }}</label>

                          <select class="form-control col-sm-8" v-model="userData.currentLevel">
                            <option value="good">{{ $t('jobAppFormLabels.foreignLanguageLevel.good') }}
                            </option>
                            <option value="medium">{{ $t('jobAppFormLabels.foreignLanguageLevel.medium')
                            }}</option>
                            <option value="bad">{{ $t('jobAppFormLabels.foreignLanguageLevel.bad') }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group row">
                          <div class="col-sm-8 offset-sm-4">
                            <button type="button" class="btn btn-primary" @click="addLanguage">{{
                              $t('jobAppFormLabels.add')
                            }}</button>
                          </div>
                        </div>

                        <!-- Display added languages -->
                        <div v-for="(language, index) in userData.languages" :key="index">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label blue"></label>
                            <div class="col-sm-8">
                              <p
                                style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 0.5rem;">
                                {{ $t(`jobAppFormLabels.foreignLanguageOption.${language.language}`) }} - {{
                                  $t(`jobAppFormLabels.foreignLanguageLevel.${language.level}`) }}
                                <button style="width: auto; background: red; border-color: red; min-height: auto;"
                                  type="button" class="btn btn-danger btn-sm" @click="removeLanguage(index)">x
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="homeAddress">{{
                            $t('jobAppFormLabels.homeAddress')
                          }} *</label><br>
                          <!-- Interpolation between <textarea>{{ test }}</textarea> doesn't work!-->
                          <textarea id="homeAddress" rows="5" class="form-control col-sm-8" v-model="userData.homeAddress"
                            :class="{ errror: $v.userData.homeAddress.$error }"
                            @blur="$v.userData.homeAddress.$touch()">></textarea>
                        </div>
                        <div v-if="$v.userData.homeAddress.$error">
                          <p v-if="!$v.userData.homeAddress.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.homeAddressRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="location">{{ $t('contactFormLabels.location')
                          }} *</label>
                          <select id="location" class="form-control col-sm-8" v-model="selectedLocation"
                            :class="{ errror: $v.selectedLocation.$error }" @blur="$v.selectedLocation.$touch()">
                            <option v-for="(location, key) in locations" :key="key"> {{ location }} </option>
                          </select>
                        </div>
                        <div v-if="$v.selectedLocation.$error">
                          <p v-if="!$v.selectedLocation.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.selectedLocationRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="town">{{ $t('jobAppFormLabels.town') }}
                            *</label>
                          <input type="text" id="town" class="form-control col-sm-8" v-model="town"
                            :class="{ errror: $v.town.$error }" @blur="$v.town.$touch()">
                        </div>
                        <div v-if="$v.town.$error">
                          <p v-if="!$v.town.required" class="text-danger mb-3">{{ $t('jobAppFormLabels.townRequired') }}
                          </p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="militaryStatus">{{ $t("militaryStatus") }}
                            *</label>
                          <select id="militaryStatus" class="form-control col-sm-8" v-model="userData.militaryStatus"
                            :class="{ errror: $v.userData.militaryStatus.$error }"
                            @blur="$v.userData.militaryStatus.$touch()">
                            <option>{{ $t("jobAppFormLabels.militaryStatus.military_1") }}</option>
                            <option>{{ $t("jobAppFormLabels.militaryStatus.military_2") }}</option>
                            <option>{{ $t("jobAppFormLabels.militaryStatus.military_3") }}</option>
                          </select>
                        </div>
                        <div v-if="$v.userData.militaryStatus.$error">
                          <p v-if="!$v.userData.militaryStatus.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.militaryStatusRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.drivingLicense') }}
                            *</label>
                          <div class="col-sm-8">
                            <label class="radio-inline">
                              <input type="radio" v-model="userData.drivingLicense" value="Yes"> {{
                                $t('jobAppFormLabels.drivingLicenseOption.drivingLicenseOption_1') }}
                            </label>
                            <label class="radio-inline">
                              <input type="radio" v-model="userData.drivingLicense" value="No"> {{
                                $t('jobAppFormLabels.drivingLicenseOption.drivingLicenseOption_2') }}
                            </label>
                          </div>
                        </div>

                        <div class="form-group row" v-if="userData.drivingLicense === 'Yes'">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.drivingLicenseClass')
                          }}</label>
                          <input type="text" class="form-control col-sm-8" v-model="userData.drivingLicenseClass"
                            :class="{ error: $v.userData.drivingLicenseClass.$error }"
                            @blur="$v.userData.drivingLicenseClass.$touch()">
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="phoneNumber">{{
                            $t('contactFormLabels.phoneNumber')
                          }} *</label>
                          <input type="number" id="phoneNumber" class="form-control col-sm-8"
                            v-model="userData.phoneNumber" :class="{ errror: $v.userData.phoneNumber.$error }"
                            @blur="$v.userData.phoneNumber.$touch()">
                        </div>

                        <div v-if="$v.userData.phoneNumber.$error">
                          <p v-if="!$v.userData.phoneNumber.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.phoneNumberRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="email">{{ $t('contactFormLabels.email') }}
                            *</label>
                          <input type="text" id="email" class="form-control col-sm-8" v-model="userData.email"
                            :class="{ errror: $v.userData.email.$error }" @blur="$v.userData.email.$touch()"
                            autocomplete="off">
                        </div>
                        <div v-if="$v.userData.email.$error">
                          <p v-if="!$v.userData.email.email" class="text-danger mb-3">{{
                            $t('contactFormLabels.emailValid')
                          }}</p>
                          <p v-if="!$v.userData.email.required" class="text-danger mb-3">{{
                            $t('contactFormLabels.emailRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.totalWorkExperience')
                          }}</label>

                          <input type="text" class="form-control col-sm-8" v-model="userData.totalWorkExperience"
                            :class="{ error: !$v.userData.totalWorkExperience.required }"
                            @blur="$v.userData.totalWorkExperience.$touch()">
                        </div>

                        <div v-if="$v.userData.totalWorkExperience.$error">
                          <p v-if="!$v.userData.totalWorkExperience.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.totalWorkExperienceRequired') }}</p>
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('jobAppFormLabels.positionYouApply')
                          }}</label>


                          <input type="text" class="form-control col-sm-8" v-model="userData.positionApplyingFor"
                            :class="{ error: !$v.userData.positionApplyingFor.required }"
                            @blur="$v.userData.positionApplyingFor.$touch()">
                        </div>

                        <div v-if="$v.userData.positionApplyingFor.$error">
                          <p v-if="!$v.userData.positionApplyingFor.required" class="text-danger mb-3">
                            {{ $t('jobAppFormLabels.positionApplyingForRequired') }}
                          </p>
                        </div>

                        <div class="form-group row">
                          <b><label class="col-sm-12 col-form-label blue">{{ $t('jobAppFormLabels.contactPerson')
                          }}</label></b>
                        </div>
                        <div class="form-group row">

                          <label class="col-sm-4 col-form-label blue">{{
                            $t('jobAppFormLabels.contactPersonOption.contactPersonOption_1')
                          }}</label>

                          <input type="text" class="form-control col-sm-8" v-model="userData.emergencyContact.name"
                            :class="{ error: !$v.userData.emergencyContact.name.required }"
                            @blur="$v.userData.emergencyContact.name.$touch()">
                        </div>

                        <div v-if="$v.userData.emergencyContact.name.$error">
                          <p v-if="!$v.userData.emergencyContact.name.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.emergencyContactNameRequired') }}</p>
                        </div>



                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{
                            $t('jobAppFormLabels.contactPersonOption.contactPersonOption_2') }}</label>

                          <input type="text" class="form-control col-sm-8"
                            v-model="userData.emergencyContact.relationship"
                            :class="{ error: !$v.userData.emergencyContact.relationship.required }"
                            @blur="$v.userData.emergencyContact.relationship.$touch()">
                        </div>

                        <div v-if="$v.userData.emergencyContact.relationship.$error">
                          <p v-if="!$v.userData.emergencyContact.relationship.required" class="text-danger mb-3">{{
                            $t('jobAppFormLabels.emergencyContactRelationshipRequired') }}</p>
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{
                            $t('jobAppFormLabels.contactPersonOption.contactPersonOption_3') }}</label>

                          <input type="text" class="form-control col-sm-8" v-model="userData.emergencyContact.phoneNumber"
                            :class="{ error: !$v.userData.emergencyContact.phoneNumber.required }"
                            @blur="$v.userData.emergencyContact.phoneNumber.$touch()">
                        </div>
                        <div v-if="$v.userData.emergencyContact.phoneNumber.$error">
                          <p v-if="!$v.userData.emergencyContact.phoneNumber.required" class="text-danger mb-3">

                            {{ $t('jobAppFormLabels.emergencyContactPhoneNumberRequired') }}

                          </p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue">{{ $t('kvkkConsent') }} *</label>
                          <div class="col-sm-8">
                            <label class="checkbox-inline">
                              <input type="checkbox" v-model="userData.kvkkConsent" required>
                              {{ $t('kvkkConsentText') }}
                            </label>
                          </div>
                        </div>







                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                            <div class="col"></div>
                            <div class="col-xs-12 col-md-8 offset-md-4">

                              <!--mine  -->
                              <vue-recaptcha ref="invisibleRecaptcha" @verify="onVerify" @expired="onExpired"
                                size="invisible" :sitekey="this.sitekey">
                              </vue-recaptcha>
                            </div>
                          </div>

                        </div>





                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="col"></div>
                        <div class="col-xs-12 col-md-8 offset-md-4" style="display: flex;">
                          <button :disabled="$v.$invalid || (!userData.kvkkConsent)" class="btn btn-primary w-50" @click.prevent="submitted">{{
                            $t('jobAppFormLabels.applyButton')
                          }}</button>
                          <button class="btn btn-reset" @click.prevent="resetForm">{{ $t('jobAppFormLabels.clear') }}</button>

                        </div>
                        <div v-if="$v.$anyError" class="text-danger text-center mb-3 mt-3">{{
                          $t('contactFormLabels.fillRequiredFields')
                        }}</div>
                      </div>
                    </div>
                  </form>
                  <hr>
                  <p><small>{{ $t('contactFormLabels.requiredFields') }}</small></p>
                  <p><small>{{ $t('contactFormLabels.note') }}</small></p>

                </div>

              </section>

            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';

/* mine*/
import VueRecaptcha from 'vue-recaptcha';
import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import Footer from "@/components/shared/Footer.vue";
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      sitekey: '6LeHSqYZAAAAAIABXNPEW24txMYUSBwLEyVWjTri',
      secretkey: '6LeHSqYZAAAAAPiF8FaHJ3V59toGYd2ltMeXBd8D',
      pageSlug: this.$route.params.slug,
      errors: [],
      FormResult: null,
      userData: {
        userName: '',
        userSurname: '',
        dateOfBirth: '',
        education: '',
        homeAddress: '',
        militaryStatus: '',
        phoneNumber: '',
        email: '',
        location: '',
        identificationNumber: '',
        gender: '',
        maritalStatus: '',
        drivingLicense: '',
        drivingLicenseClass: '',
        securityTraining: 'NotTaken',
        showValidityDate: false,
        languages: [], // Array to store languages and levels
        currentLanguage: '', // Current language being added
        currentLevel: '', // Current level being added
        totalWorkExperience: '', // Total Work Experience
        positionApplyingFor: '', // Position You Are Applying For
        emergencyContact: {
          name: '', // Person to Call in Emergency - Name
          relationship: '', // Person to Call in Emergency - Degree of Relationship
          phoneNumber: '', // Person to Call in Emergency - Phone Number
          kvkkConsent: false // KVKK consent checkbox
        }
      },
      isSubmitted: false,
      locations: ['ADANA', 'ADIYAMAN', 'AFYONKARAHİSAR', 'AĞRI', 'AMASYA', 'ANKARA', 'ANTALYA', 'ARTVİN', 'AYDIN', 'BALIKESİR', 'BİLECİK', 'BİNGÖL', 'BİTLİS', 'BOLU', 'BURDUR', 'BURSA', 'ÇANAKKALE', 'ÇANKIRI', 'ÇORUM', 'DENİZLİ', 'DİYARBAKIR', 'EDİRNE', 'ELAZIĞ', 'ERZİNCAN', 'ERZURUM', 'ESKİŞEHİR', 'GAZİANTEP', 'GİRESUN', 'GÜMÜŞHANE', 'HAKKARİ', 'HATAY', 'ISPARTA', 'MERSİN', 'İSTANBUL', 'İZMİR', 'KARS', 'KASTAMONU', 'KAYSERİ', 'KIRKLARELİ', 'KIRŞEHİR', 'KOCAELİ', 'KONYA', 'KÜTAHYA', 'MALATYA', 'MANİSA', 'KAHRAMANMARAŞ', 'MARDİN', 'MUĞLA', 'MUŞ', 'NEVŞEHİR', 'NİĞDE', 'ORDU', 'RİZE', 'SAKARYA', 'SAMSUN', 'SİİRT', 'SİNOP', 'SİVAS', 'TEKİRDAĞ', 'TOKAT', 'TRABZON', 'TUNCELİ', 'ŞANLIURFA', 'UŞAK', 'VAN', 'YOZGAT', 'ZONGULDAK', 'AKSARAY', 'BAYBURT', 'KARAMAN', 'KIRIKKALE', 'BATMAN', 'ŞIRNAK', 'BARTIN', 'ARDAHAN', 'IĞDIR', 'YALOVA', 'KARABüK', 'KİLİS', 'OSMANİYE', 'DÜZCE'],
      town: '',
      selectedLocation: '',
    }
  },
  head: {
    script: [
      { type: 'text/javascript', src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, body: true },
      { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' }
    ]
  },
  components: {
    NavBar,
    Breadcrumb,
    SideBar,
    VueRecaptcha,
    Footer
  },
  validations: {
    userData: {
      userName: {
        required
      },
      userSurname: {
        required
      },
      dateOfBirth: {
        required
      },
      education: {
        required
      },
      homeAddress: {
        required
      },
      militaryStatus: {
        required
      },
      phoneNumber: {
        required
      },
      email: {
        required,
        email
      },
      identificationNumber: {
      },
      gender: {
        required
      },
      maritalStatus: {
        required
      },
      drivingLicense: {
        required
      },
      drivingLicenseClass: {
      },
      securityTraining: {
        required
      },
      validityDate: {
      },
      totalWorkExperience: {
        required,
      },
      positionApplyingFor: {
        required,
      },
      emergencyContact: {
        name: {
          required,
        },
        relationship: {
          required,
        },
        phoneNumber: {
          required,
        },
        kvkkConsent: {
          required,
        },
      }

    },
    town: {
      required
    },
    selectedLocation: {
      required
    }

  },


  methods: {

    addLanguage() {
      // Add the selected language and level to userData.languages
      if (this.userData.currentLanguage && this.userData.currentLevel) {
        this.userData.languages.push({
          language: this.userData.currentLanguage,
          level: this.userData.currentLevel
        });

        // Reset language and level selections
        this.userData.currentLanguage = '';
        this.userData.currentLevel = '';
      }
    },

    removeLanguage(index) {
      // Remove a language and level at the specified index
      this.userData.languages.splice(index, 1);
    },

    submitted(e) {
      if (!this.userData.kvkkConsent) {
      this.errors.push('Please check KVKK consents.');
      return;
    }
      this.isSubmitted = true;
      e.preventDefault();
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function (recaptchaToken) {
      this.resetForm();
      this.resetRecaptcha();

      let config = {
        headers: {
          "Authorization": "Token 3f1c945dda80e43cd5683c8443092787",
          "Content-Type": "application/json"
        }
      }
      let myData = {
        content:
          'Name' + '=' + this.userData.userName + '&' +
          'Surname' + '=' + this.userData.userSurname + '&' +
          'identificationNumber' + '=' + this.userData.identificationNumber + '&' + // Identification Number
          'gender' + '=' + this.userData.gender + '&' + // Gender
          'dateOfBirth' + '=' + this.userData.dateOfBirth + '&' + // Date of Birth
          'maritalStatus' + '=' + this.userData.maritalStatus + '&' + // Marital Status
          'education' + '=' + this.userData.education + '&' + // Education
          'privateSecurityTraining' + '=' + this.userData.securityTraining + '&' + // Private Security Training
          'validityDate' + '=' + (this.userData.showValidityDate ? this.userData.validityDate : '') + '&' + // Validity Date
          'foreignLanguage' + '=' + JSON.stringify(this.userData.languages) + '&' + // Foreign Languages
          'homeAddress' + '=' + this.userData.homeAddress + '&' + // Home Address
          'location' + '=' + this.selectedLocation + '&' + // Location
          'town' + '=' + this.town + '&' + // Town
          'militaryStatus' + '=' + this.userData.militaryStatus + '&' + // Military Status
          'drivingLicense' + '=' + this.userData.drivingLicense + '&' + // Driving License
          'drivingLicenseClass' + '=' + this.userData.drivingLicenseClass + '&' + // Driving License Class
          'phoneNumber' + '=' + this.userData.phoneNumber + '&' + // Phone Number
          'email' + '=' + this.userData.email + '&' + // Email Address
          'totalWorkExperience' + '=' + this.userData.totalWorkExperience + '&' + // Total Work Experience
          'positionApplyingFor' + '=' + this.userData.positionApplyingFor + '&' + // Position You Are Applying For
          'emergencyContactName' + '=' + this.userData.emergencyContact.name + '&' + // Emergency Contact - Name
          'emergencyContactRelationship' + '=' + this.userData.emergencyContact.relationship + '&' + // Emergency Contact - Degree of Relationship
          'emergencyContactPhoneNumber' + '=' + this.userData.emergencyContact.phoneNumber, // Emergency Contact - Phone Number
        application: this.$application,
        language: this.$t("languageCode"),
        dynamicField: '5ee7e12c9a24df0df44eeedd'
      };

      let secondData = JSON.stringify(myData);
      let successMessage = this.$t('yourMessageHasBeenSent');
      let errorMessage = this.$t('errorMessage');

      axios.post('https://recaptcha-verifier.tavitlab.com', {
        siteKey: this.sitekey,
        captchaToken: recaptchaToken
      })
        .then(function (response) {
          if (response.data.result == true) {

            axios.post('https://api.tavguvenlik.com/forms', secondData, config)
              .then(response => {
                if (response.data.result == true) {
                  alert(successMessage);
                } else {
                  alert(errorMessage);
                }
                //document.location = document.location.href;
              })
              .catch(error => {
                console.log(error)
                alert(errorMessage);
                //document.location = document.location.href;
              })

          } else {
            // document.location = document.location.href;
          }
        })
        .catch(function (error) {
          console.log(error);
          // document.location = document.location.href;
        });
      this.resetForm();
    },
    onExpired: function () {
      this.resetRecaptcha();

    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset();
    },
    resetForm() {
      this.$refs.contactUsForm.reset();
    }
  }
}

</script>