<template>
  <section id="LinesOfActivity" class="section section-2 light-background">
    <div class="container">
      <h2>{{ $t("fieldsOfActivity") }}</h2>
      <div class="row justify-space-between">
        <div
          class="col-md-4"
          v-for="loaItem of loaItems"
          v-bind:key="loaItem._id"
        >
          <a :href="loaItem.link">
            <div class="image-container">
              <img
                v-if="loaItem.listImage"
                :src="
                  'https://webcmstavsecurity.tav.aero/uploads/5e5d10f0059b14822c6f256e/static-pages/list-images/' +
                    loaItem.listImage
                "
                alt=""
                width="100%"
              />
              <img v-else src="@/assets/img/ph-loa.jpg" alt="" width="100%" />
              <p>{{ loaItem.title }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContentService from "@/services/ContentService.js";
export default {
  data() {
    return {
      loaItems: [],
    };
  },
  created() {
    ContentService.getLinesOfActivity(this.$application, this.$t("languageCode"))
      .then((res) => (this.loaItems = res.data.data))
      .catch((error) => console.log("There was an error:" + error));
  },
};
</script>

<style></style>
