<template>

    <div id="Survey" class="col-lg-4">
        <div class="section">
            <h2>{{ $t('survey') }}</h2>
            <div class="survey">
                <vue-poll v-bind="options" @addvote="addVote" />
                <img src="./../assets/img/checked-shield-icon.svg" alt="Shield icon">
            </div>
        </div>
    </div>
    
</template>


    <script> 

          
    import VuePoll from 'vue-poll'
    
    export default {        
        data() {
            return {
                options: {
                    question: this.$t("surveyLabels.surveyQuestion"),
                    answers: [
                        { value: 1, text: this.$t("surveyLabels.answerOne"), votes: 35 },
                        { value: 2, text: this.$t("surveyLabels.answerTwo"), votes: 19 },
                        { value: 3, text: this.$t("surveyLabels.answerThree"), votes: 10 },
                        { value: 4, text: this.$t("surveyLabels.answerFour"), votes: 34 } 
                    ]
                }
            }
        },
        components: {
            VuePoll
        },
        methods: {
            addVote(obj){
                console.log('You voted ' + obj.value + '!');
            }
        }
    }
    </script>
