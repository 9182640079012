import Home from '@/views/Home.vue';
import PressRoom from '@/views/PressRoom.vue';
import TAVSecurityInPress from '@/views/TAVSecurityInPress.vue';
import Feedback from '@/views/Feedback.vue';
import ContactUs from '@/views/ContactUs.vue';
import FrequentlyAsked from '@/views/FrequentlyAsked.vue';
import JobApplication from '@/views/JobApplication.vue';
import VideoGallery from '@/views/VideoGallery.vue';
import Announcements from '@/views/Announcements.vue';




import i18n from '@/i18n'

export const routes = [
    {
        path: '/',
        redirect: `/${i18n.locale}`
    },
    {
        path: '/:lang',
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            { 
                path: '/', 
                name: 'home',
                component: Home,
                props: true
              },
            {
                path: ':slug/haberler',
                name: 'PressRoomTR',
                component: PressRoom,
                props: true

            },
            {
                path: ':slug/news',
                name: 'PressRoom',
                component: PressRoom,
                props: true

            },
            {
                path: ':slug/basinda-tav-guvenlik',
                name: 'TAVSecurityInPressTR',
                component: TAVSecurityInPress,
                props: true

            },
            {
                path: ':slug/tav-security-in-press',
                name: 'TAVSecurityInPress',
                component: TAVSecurityInPress,
                props: true

            },
            {
                path: ':slug/yayinlar',
                name: 'AnnouncementsTR',
                component: Announcements,
                props: true

            },
            {
                path: ':slug/publications',
                name: 'Announcements',
                component: Announcements,
                props: true

            },
            {
                path: ':slug/feedback-message-box',
                name: 'Feedback',
                component: Feedback,
                props: true

            },
            {
                path: ':slug/gorus-ve-onerileriniz',
                name: 'FeedbackTR',
                component: Feedback,
                props: true

            },
            {
                path: ':slug/bize-ulasin',
                name: 'ContactUs',
                component: ContactUs,
                props: true

            },
            {
                path: ':slug/movie',
                name: 'VideoGallery',
                component: VideoGallery,
                props: true

            },
            {
                path: ':slug/tanitim-filmi',
                name: 'VideoGalleryTR',
                component: VideoGallery,
                props: true

            },
            {
                path: ':slug/job-application',
                name: 'JobApplication',
                component: JobApplication,
                props: true

            },
            {
                path: ':slug/is-basvuru-formu',
                name: 'JobApplicationTR',
                component: JobApplication,
                props: true

            },
            {
                path: ':slug/sikca-sorulan-sorular',
                name: 'FrequentlyAskedTR',
                component: FrequentlyAsked,
                props: true

            },
            {
                path: ':slug/frequently-asked-questions',
                name: 'FrequentlyAsked',
                component: FrequentlyAsked,
                props: true

            },
            { 
                path: ":slug", 
                name:"Pages", 
                props: true,
                component: () =>
                    import(/* webpackChunkName: "Pages" */ "@/views/Pages"),
                children: [ 
                    
                    {
                        path: ":pageDetailSlug",
                        name: "PageDetails",
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "PageDetails" */ "@/views/PageDetails")
                    }
                ] 
            },
            { path: 'redirect-me', redirect: { name: 'home' } },
            { path: '*', redirect: '/' } //redirects the non-existent pages to home page
        ]
    }
];