<template>
  <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages">
            <div>
              <section id="ContactForm" class="section">
                <div class="container">
                  <h1 class="main-title">{{ $t("feedbackMessageBox") }}</h1>
                  <b-alert v-if="isSubmitted" show variant="success">{{ $t("yourMessageHasBeenSent") }}</b-alert>
                  <form ref="contactUsForm" @submit.prevent="submitted" autocomplete="off">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="contactReason">{{
                              $t("contactFormLabels.contactReason")
                          }}</label>
                          <select id="contactReason" class="form-control col-sm-8" v-model="selectedReason">
                            <option>{{ $t("contactFormLabels.contactReasonLabels.suggestion") }}</option>
                            <option>{{ $t("contactFormLabels.contactReasonLabels.complaint") }}</option>
                          </select>
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="userName">{{
                              $t('contactFormLabels.nameSurname')
                          }} *</label>
                          <input type="text" id="userName" class="form-control col-sm-8" v-model="userData.userName"
                            :class="{ errror: $v.userData.userName.$error }" @blur="$v.userData.userName.$touch()">
                        </div>
                        <div v-if="$v.userData.userName.$error">
                          <p v-if="!$v.userData.userName.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.nameSurnameRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="company">{{ $t('contactFormLabels.company')
                          }}</label>
                          <input type="text" id="company" class="form-control col-sm-8" v-model="userData.company">
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="jobTitle">{{ $t('contactFormLabels.title')
                          }}</label>
                          <input type="text" id="jobTitle" class="form-control col-sm-8" v-model="userData.jobTitle">
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="email">{{ $t('contactFormLabels.email') }}
                            *</label>
                          <input type="text" id="email" class="form-control col-sm-8" v-model="userData.email"
                            :class="{ errror: $v.userData.email.$error }" @blur="$v.userData.email.$touch()">
                        </div>
                        <div v-if="$v.userData.email.$error">
                          <p v-if="!$v.userData.email.email" class="text-danger mb-3">{{
                              $t('contactFormLabels.emailValid')
                          }}</p>
                          <p v-if="!$v.userData.email.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.emailRequired')
                          }}</p>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="phoneNumber">{{
                              $t('contactFormLabels.phoneNumber')
                          }} *</label>
                          <input type="number" id="phoneNumber" class="form-control col-sm-8"
                            v-model="userData.phoneNumber" :class="{ errror: $v.userData.phoneNumber.$error }"
                            @blur="$v.userData.phoneNumber.$touch()">
                        </div>
                        <div v-if="$v.userData.phoneNumber.$error">
                          <p v-if="!$v.userData.phoneNumber.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.phoneNumberRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="location">{{ $t('contactFormLabels.location')
                          }}</label>
                          <select id="location" class="form-control col-sm-8" v-model="selectedLocation">
                            <option v-for="(location, key) in locations" :key="key"> {{ location }} </option>
                          </select>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="dateOfBirth">{{
                              $t('contactFormLabels.birthDate')
                          }}</label>
                          <input type="date" id="dateOfBirth" class="form-control col-sm-8"
                            v-model="userData.dateOfBirth">
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="message">{{ $t('contactFormLabels.message')
                          }} *</label><br>
                          <!-- Interpolation between <textarea>{{ test }}</textarea> doesn't work!-->
                          <textarea id="message" rows="5" class="form-control col-sm-8" v-model="userData.message"
                            :class="{ errror: $v.userData.message.$error }"
                            @blur="$v.userData.message.$touch()"></textarea>
                        </div>
                        <div v-if="$v.userData.message.$error">
                          <p v-if="!$v.userData.message.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.messageRequired')
                          }}</p>
                        </div>

                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                            <div class="col"></div>
                            <div class="col-xs-12 col-md-8 offset-md-4">
                              <!--mine  -->
                              <vue-recaptcha ref="invisibleRecaptcha" @verify="onVerify" @expired="onExpired"
                                size="invisible" :sitekey="this.sitekey">
                              </vue-recaptcha>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="col"></div>
                        <div class="col-xs-12 col-md-8 offset-md-4" style="display: flex;">
                          <button :disabled="$v.$invalid" class="btn btn-primary" @click.prevent="submitted">{{
                              $t('contactFormLabels.contactUsButton')
                          }}</button> 
                          <button class="btn btn-reset" @click.prevent="resetForm">Temizle</button>

                        </div>
                        <div v-if="$v.$anyError" class="text-danger text-center mb-3 mt-3">{{
                            $t('contactFormLabels.fillRequiredFields')
                        }}</div>
                      </div>
                    </div>
                  </form>
                  <hr>
                  <p><small>{{ $t('contactFormLabels.requiredFields') }}</small></p>
                  <p><small>{{ $t('contactFormLabels.note') }}</small></p>

                </div>

              </section>

            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from 'axios';

/* mine*/
import VueRecaptcha from 'vue-recaptcha';
import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import Footer from "@/components/shared/Footer.vue";
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      sitekey: '6LeHSqYZAAAAAIABXNPEW24txMYUSBwLEyVWjTri',
      secretkey: '6LeHSqYZAAAAAPiF8FaHJ3V59toGYd2ltMeXBd8D',
      pageSlug: this.$route.params.slug,
      errors: [],
      FormResult: null,
      userData: {
        email: this.$route.params.ffemail,
        userName: this.$route.params.ffname,
        userSurname: '',
        company: '',
        jobTitle: '',
        phoneNumber: '',
        location: '',
        dateOfBirth: '',
        message: this.$route.params.ffmessage
      },
      isSubmitted: false,
      locations: ['ADANA', 'ADIYAMAN', 'AFYONKARAHİSAR', 'AĞRI', 'AMASYA', 'ANKARA', 'ANTALYA', 'ARTVİN', 'AYDIN', 'BALIKESİR', 'BİLECİK', 'BİNGÖL', 'BİTLİS', 'BOLU', 'BURDUR', 'BURSA', 'ÇANAKKALE', 'ÇANKIRI', 'ÇORUM', 'DENİZLİ', 'DİYARBAKIR', 'EDİRNE', 'ELAZIĞ', 'ERZİNCAN', 'ERZURUM', 'ESKİŞEHİR', 'GAZİANTEP', 'GİRESUN', 'GÜMÜŞHANE', 'HAKKARİ', 'HATAY', 'ISPARTA', 'MERSİN', 'İSTANBUL', 'İZMİR', 'KARS', 'KASTAMONU', 'KAYSERİ', 'KIRKLARELİ', 'KIRŞEHİR', 'KOCAELİ', 'KONYA', 'KÜTAHYA', 'MALATYA', 'MANİSA', 'KAHRAMANMARAŞ', 'MARDİN', 'MUĞLA', 'MUŞ', 'NEVŞEHİR', 'NİĞDE', 'ORDU', 'RİZE', 'SAKARYA', 'SAMSUN', 'SİİRT', 'SİNOP', 'SİVAS', 'TEKİRDAĞ', 'TOKAT', 'TRABZON', 'TUNCELİ', 'ŞANLIURFA', 'UŞAK', 'VAN', 'YOZGAT', 'ZONGULDAK', 'AKSARAY', 'BAYBURT', 'KARAMAN', 'KIRIKKALE', 'BATMAN', 'ŞIRNAK', 'BARTIN', 'ARDAHAN', 'IĞDIR', 'YALOVA', 'KARABüK', 'KİLİS', 'OSMANİYE', 'DÜZCE'],
      selectedLocation: '',
      selectedReason: ''
    }
  },
  head: {
    script: [
      { type: 'text/javascript', src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, body: true },
      { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' }
    ]
  },
  components: {
    NavBar,
    Breadcrumb,
    SideBar,
    VueRecaptcha,
    Footer
  },
  validations: {
    userData: {
      email: {
        required,
        email
      },
      userName: {
        required
      },
      phoneNumber: {
        required
      },
      message: {
        required
      }
    }
  },
  methods: {
    submitted(e) {
      this.isSubmitted = true;
      e.preventDefault();
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function (recaptchaToken) {
      this.resetForm();
      this.resetRecaptcha();
      let config = {
        headers: {
          "Authorization": "Token 3f1c945dda80e43cd5683c8443092787",
          "Content-Type": "application/json"
        }
      }

      let myData = {
        content:
          'EMail' + '=' + this.userData.email + '&'
          + 'ContactReason' + '=' + this.selectedReason + '&'
          + 'NameSurname' + '=' + this.userData.userName + '&'
          + 'Company' + '=' + this.userData.company + '&'
          + 'JobTitle' + '=' + this.userData.jobTitle + '&'
          + 'PhoneNumber' + '=' + this.userData.phoneNumber + '&'
          + 'Location' + '=' + this.selectedLocation + '&'
          + 'dateOfBirth' + '=' + this.userData.dateOfBirth + '&'
          + 'Message' + '=' + this.userData.message,
        application: this.$application,
        language: this.$t("languageCode"),
        dynamicField: '5ec5092f0b1a863328749cc3'
      };

      let secondData = JSON.stringify(myData);
      let successMessage = this.$t('yourMessageHasBeenSent');
      let errorMessage = this.$t('errorMessage');

      axios.post('https://recaptcha-verifier.tavitlab.com', {
        siteKey: this.sitekey,
        captchaToken: recaptchaToken
      })
        .then(function (response) {
          if (response.data.result == true) {

            axios.post('https://api.tavguvenlik.com/forms', secondData, config)
              .then(response => {
                if (response.data.result == true) {
                  alert(successMessage);
                } else {
                  alert(errorMessage);
                }
                //document.location = document.location.href;
              })
              .catch(error => {
                console.log(error)
                alert(errorMessage);
                //document.location = document.location.href;
              })

          } else {
           // document.location = document.location.href;
          }
        })
        .catch(function (error) {
          console.log(error);
          //document.location = document.location.href;
        });
        this.resetForm();
    },
    onExpired: function () {
      this.resetRecaptcha();
    
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset();
    },
    resetForm() {
          this.$refs.contactUsForm.reset();
    }
  }
}
</script>
