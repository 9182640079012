<template>
  <div id="app">
    <transition name="slide" mode="out-in">
      <router-view :key="$route.path"></router-view
      ><!-- mine check-->
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",

  computed: {
    cWindowTitle() {
      let title = this.$t("windowTitle.base");

      return title;
    },
  },
  watch: {
    cWindowTitle: "setWindowTitle",
  },
  created() {
    this.setWindowTitle();
  },
  methods: {
    setWindowTitle() {
      document.title = this.cWindowTitle;
    },
  },
};
</script>

<style>
/*.slide-leave-active {
  transition: opacity 1s ease;
  opacity: 0;
  animation: slide-out 1s ease-out forwards;
}

.slide-leave {
  opacity: 1;
  transform: translateX(0);
}

.slide-enter-active {
  animation: slide-in 1s ease-out forwards;
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}*/
</style>
