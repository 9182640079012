<template>
  <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages">
            <div>
              <section id="ContactForm" class="section">
                <div class="container">
                  <h1 class="main-title">Bize Ulaşın</h1>
                  <b-alert v-if="isSubmitted" show variant="success">{{ $t("yourMessageHasBeenSent") }}</b-alert>
                  <form ref="contactUsForm" @submit.prevent="submitted" autocomplete="off">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                       
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="nameSurname">{{
                              $t('contactFormLabels.nameSurname')
                          }} *</label>
                          <input type="text" id="nameSurname" class="form-control col-sm-8" v-model="userData.nameSurname"
                            :class="{ errror: $v.userData.nameSurname.$error }" @blur="$v.userData.nameSurname.$touch()"  autocomplete="off">
                        </div>
                        <div v-if="$v.userData.nameSurname.$error">
                          <p v-if="!$v.userData.nameSurname.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.nameSurnameRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="company">{{
                              $t('contactFormLabels.company')
                          }} *</label>
                          <input type="text" id="company" class="form-control col-sm-8" v-model="userData.company"
                            :class="{ errror: $v.userData.company.$error }" @blur="$v.userData.company.$touch()" autocomplete="off">
                        </div>
                        <div v-if="$v.userData.company.$error">
                          <p v-if="!$v.userData.company.required" class="text-danger mb-3">Lütfen şirket giriniz.</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="companyAddress">Şirket Adresi *</label><br>
                          <!-- Interpolation between <textarea>{{ test }}</textarea> doesn't work!-->
                          <textarea id="companyAddress" rows="5" class="form-control col-sm-8" v-model="userData.companyAddress"
                            :class="{ errror: $v.userData.companyAddress.$error }"
                            @blur="$v.userData.companyAddress.$touch()"  autocomplete="off"></textarea>
                        </div>
                        <div v-if="$v.userData.companyAddress.$error">
                          <p v-if="!$v.userData.companyAddress.required" class="text-danger mb-3">Lütfen şirket adresini giriniz.</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="phoneNumber">{{
                              $t('contactFormLabels.phoneNumber')
                          }} *</label>
                          <input type="number" id="phoneNumber" class="form-control col-sm-8"
                            v-model="userData.phoneNumber" :class="{ errror: $v.userData.phoneNumber.$error }"
                            @blur="$v.userData.phoneNumber.$touch()"  autocomplete="off">
                        </div>
                        <div v-if="$v.userData.phoneNumber.$error">
                          <p v-if="!$v.userData.phoneNumber.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.phoneNumberRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="email">{{ $t('contactFormLabels.email') }}
                            *</label>
                          <input type="text" id="email" class="form-control col-sm-8" v-model="userData.email"
                            :class="{ errror: $v.userData.email.$error }" @blur="$v.userData.email.$touch()"  autocomplete="off">
                        </div>
                        <div v-if="$v.userData.email.$error">
                          <p v-if="!$v.userData.email.email" class="text-danger mb-3">{{
                              $t('contactFormLabels.emailValid')
                          }}</p>
                          <p v-if="!$v.userData.email.required" class="text-danger mb-3">{{
                              $t('contactFormLabels.emailRequired')
                          }}</p>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="selectedActivity">Hangi hizmet hakkında bilgi almak istersiniz? *</label>
                          <select id="selectedActivity" class="form-control col-sm-8" v-model="userData.selectedActivity" :class="{ errror: $v.userData.selectedActivity.$error }" @blur="$v.userData.selectedActivity.$touch()" autocomplete="off">                   
                            <option>Sivil Havacılık Güvenliği</option>
                            <option>Uçak Özel Güvenlik ve Hizmet ve Denetimi</option>
                            <option>Tesis Güvenliği</option>
                            <option>Uzaktan İzleme ve Alarm Takip Merkezi</option>
                            <option>K9 Birimi</option>
                            <option>Eğitim Hizmetleri</option>
                            <option>Elektronik Güvenlik Hizmetleri</option>
                            <option>Entegre Güvenlik Çözümleri</option>
                            <option>Risk Analizi & Güvenlik Danışmanlığı</option>
                            <option>Mobil ve Modüler Güvenlik Hizmetleri </option>
                          </select>
                        </div>
                        <div v-if="$v.userData.selectedActivity.$error">
                          <p v-if="!$v.userData.selectedActivity.required" class="text-danger mb-3">Lütfen seçiniz.</p>
                        </div>
                      

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="contactVia">Bize nasıl ulaştınız?</label>
                          <select id="contactVia" class="form-control col-sm-8" v-model="contactVia"  autocomplete="off">
                            <option>Tavsiye</option>
                            <option>Sosyal Medya</option>
                            <option>İnternet Araması</option>
                            <option>Basın ve Yayın</option>
                            <option>Diğer</option>
                          </select>
                        </div>

                        

                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label blue" for="message">{{ $t('contactFormLabels.message')
                          }}</label><br>
                          <!-- Interpolation between <textarea>{{ test }}</textarea> doesn't work!-->
                          <textarea id="message" rows="5" class="form-control col-sm-8" v-model="message"  autocomplete="off"></textarea>
                        </div>

                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                            <div class="col"></div>
                            <div class="col-xs-12 col-md-8 offset-md-4">
                              <!--mine  -->
                              <vue-recaptcha ref="invisibleRecaptcha" @verify="onVerify" @expired="onExpired"
                                size="invisible" :sitekey="this.sitekey">
                              </vue-recaptcha>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div class="col"></div>
                        <div class="col-xs-12 col-md-8 offset-md-4" style="display:flex;">
                          <button :disabled="$v.$invalid" class="btn btn-primary" @click.prevent="submitted">{{
                              $t('contactFormLabels.contactUsButton')
                          }}</button> 
                          <button class="btn btn-reset" @click.prevent="resetForm">Temizle</button>
                        </div>
                        <div v-if="$v.$anyError" class="text-danger text-center mb-3 mt-3">{{
                            $t('contactFormLabels.fillRequiredFields')
                        }}</div>
                      </div>
                    </div>
                  </form>
                  <hr>
                  <p><small>{{ $t('contactFormLabels.requiredFields') }}</small></p>
                  <p><small>{{ $t('contactFormLabels.note') }}</small></p>

                </div>

              </section>

            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from 'axios';

/* mine*/
import VueRecaptcha from 'vue-recaptcha';
import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import Footer from "@/components/shared/Footer.vue";
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      sitekey: '6LeHSqYZAAAAAIABXNPEW24txMYUSBwLEyVWjTri',
      secretkey: '6LeHSqYZAAAAAPiF8FaHJ3V59toGYd2ltMeXBd8D',
      pageSlug: this.$route.params.slug,
      errors: [],
      FormResult: null,
      userData: {
        email: this.$route.params.ffemail,
        nameSurname: this.$route.params.ffname,
        company: '',
        companyAddress: '',
        phoneNumber: '',
        message: this.$route.params.ffmessage
      },
      isSubmitted: false,
      contactVia: '',
      selectedActivity: ''
    }
  },
  head: {
    script: [
      { type: 'text/javascript', src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, body: true },
      { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' }
    ]
  },
  components: {
    NavBar,
    Breadcrumb,
    SideBar,
    VueRecaptcha,
    Footer
  },
  validations: {
    userData: {
      email: {
        required,
        email
      },
      nameSurname: {
        required
      },
      company: {
        required
      },
      companyAddress: {
        required
      },
      phoneNumber: {
        required
      },
      selectedActivity: {
        required
    }
    }
    
  },
  methods: {
    submitted(e) {
      this.isSubmitted = true;
      e.preventDefault();
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function (recaptchaToken) {
      this.resetForm();
      this.resetRecaptcha();
      let config = {
        headers: {
          "Authorization": "Token 3f1c945dda80e43cd5683c8443092787",
          "Content-Type": "application/json"
        }
      }

      let myData = {
        content:
          'EMail' + '=' + this.userData.email + '&'
          + 'NameSurname' + '=' + this.userData.nameSurname + '&'
          + 'Company' + '=' + this.userData.company + '&'
          + 'CompanyAddress' + '=' + this.userData.companyAddress + '&'
          + 'PhoneNumber' + '=' + this.userData.phoneNumber + '&'
          + 'SelectedActivity' + '=' + this.userData.selectedActivity + '&'
          + 'ContactVia' + '=' + this.contactVia + '&'
          + 'Message' + '=' + this.message,
        application: this.$application,
        language: this.$t("languageCode"),
        dynamicField: '647ed4a098a85a0464c7164a'
      };

      let secondData = JSON.stringify(myData);
      let successMessage = this.$t('yourMessageHasBeenSent');
      let errorMessage = this.$t('errorMessage');

      axios.post('https://recaptcha-verifier.tavitlab.com', {
        siteKey: this.sitekey,
        captchaToken: recaptchaToken
      })
        .then(function (response) {
          if (response.data.result == true) {

            axios.post('https://api.tavguvenlik.com/forms', secondData, config)
              .then(response => {
                if (response.data.result == true) {
                  alert(successMessage);
                } else {
                  alert(errorMessage);
                  
                }
                
                //document.location = document.location.href;
              })
              .catch(error => {
                console.log(error)
                //alert(errorMessage);
                
                //document.location = document.location.href;
              })

          } else {
            //document.location = document.location.href;
          }
          
        })
        .catch(function (error) {
          console.log(error);
          
          //document.location = document.location.href;
        });

        this.resetForm();
    },
    onExpired: function () {
      this.resetRecaptcha();
    
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset();
    },
    resetForm() {
          this.$refs.contactUsForm.reset();
    }
  }
}
</script>
