<template>
  <div>
    <div class="brands">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-6 d-flex">
                <img
                  src="@/assets/img/brand-1.png"
                  alt="logo"
                  width="110"
                  height="40"
                  class="mx-auto"
                />
              </div>
              <div class="col-sm-6 d-flex">
                <img
                  src="@/assets/img/brand-2.png"
                  alt="logo"
                  width="149"
                  height="40"
                  class="mx-auto"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-3 d-flex">
                <img
                  src="@/assets/img/brand-3.png"
                  alt="logo"
                  width="40"
                  height="40"
                  class="mx-auto"
                />
              </div>
              <div class="col-sm-3 d-flex">
                <img
                  src="@/assets/img/brand-4.png"
                  alt="logo"
                  width="62"
                  height="40"
                  class="mx-auto"
                />
              </div>
              <div class="col-sm-6 d-flex">
                <img
                  src="@/assets/img/brand-5.png"
                  alt="logo"
                  width="126"
                  height="40"
                  class="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer-bg-top-left"></div>
      <div class="footer-bg-top-right"></div>
      <div class="container">
        <div class="row" v-if="footerItems && footerItems.length">
          <div class="col-md-3">
            <div
              v-for="footerItem of footerItems.slice(0, 2)"
              v-bind:key="footerItem._id"
            >
              <div class="links mb-5">
                <p>{{ footerItem.value }}</p>

                <span
                  v-if="
                    footerItem.subDynamicFields &&
                      footerItem.subDynamicFields.length
                  "
                >
                  <a
                    class="nav-item"
                    v-for="subFooterItem of footerItem.subDynamicFields"
                    v-bind:key="subFooterItem._id"
                    :href="subFooterItem.link"
                  >
                    {{ subFooterItem.value }}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              v-for="footerItem of footerItems.slice(2, 4)"
              v-bind:key="footerItem._id"
            >
              <div class="links mb-5">
                <p>{{ footerItem.value }}</p>

                <span
                  v-if="
                    footerItem.subDynamicFields &&
                      footerItem.subDynamicFields.length
                  "
                >
                  <a
                    class="nav-item"
                    v-for="subFooterItem of footerItem.subDynamicFields"
                    v-bind:key="subFooterItem._id"
                    :href="subFooterItem.link"
                  >
                    {{ subFooterItem.value }}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              v-for="footerItem of footerItems.slice(4, 6)"
              v-bind:key="footerItem._id"
            >
              <div class="links mb-5">
                <p>{{ footerItem.value }}</p>

                <span
                  v-if="
                    footerItem.subDynamicFields &&
                      footerItem.subDynamicFields.length
                  "
                >
                  <a
                    class="nav-item"
                    v-for="subFooterItem of footerItem.subDynamicFields"
                    v-bind:key="subFooterItem._id"
                    :href="subFooterItem.link"
                  >
                    {{ subFooterItem.value }}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <!-- </div>-->

          <div class="col-md-3">
            <!--<div class="links">
              <p class="mb-4">
                <a href="#">{{ $t("privacyPolicy") }}</a>
              </p>
              <p class="mb-4">
                <a href="#">{{ $t("termsConditions") }}</a>
              </p>
              <p class="mb-4">
                <a href="#">{{ $t("siteMap") }}</a>
              </p>
            </div>-->
            <form>
              <div class="form-row">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('contactFormLabels.nameSurname')"
                  v-model="footerUserData.username"
                />
              </div>
              <div class="form-row">
                <input
                  type="email"
                  class="form-control"
                  :placeholder="$t('contactFormLabels.email')" 
                  v-model="footerUserData.email"
                />
              </div>
              <div class="form-row">
                <textarea
                  class="form-control"
                  :placeholder="$t('contactFormLabels.message')"
                   v-model="footerUserData.message" 
                ></textarea>
              </div>
              <div class="form-row">
                <router-link :to="{name: $t('feedbackRoute'), params: {slug:pageSlug, ffname: footerUserData.username, ffemail: footerUserData.email,ffmessage: footerUserData.message} }"> <button type="submit" class="btn">
                  {{ $t("contactFormLabels.contactUsButton") }}
                </button></router-link>
              </div>
            </form>
            <hr />
            <!--<button class="btn btn-primary" @click="fetchData">Get Data</button>
            <ul class="list-group">
              <li class="list-group-item" v-for="(u, key) in users" :key="key">
                {{ u.username }} - {{ u.email }}
              </li>
            </ul>-->
          </div>
        </div>
      </div>

      <!--<ul v-if="footerItems && footerItems.length">
                    <li v-for="footerItem of footerItems" v-bind:key="footerItem._id">
                        <p><strong>{{ footerItem.value }}</strong></p>
                         <p>{{ footerItem.link }}</p>
                    </li>
                </ul>-->

      <div class="copyrights">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-flex align-items-center">
              <p class="white">{{ $t("copyright") }}</p>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
              <div class="social">
                <a
                  :href="$t('facebookPath')"
                  target="_blank"
                  class="nav-item mr-4"
                >
                  <img
                    src="@/assets/img/facebook-icon.svg"
                    alt="Facebook icon"
                    width="36"
                    height="36"
                  />
                </a>
                <a :href="$t('twitterPath')" target="_blank" class="nav-item mr-4">
                  <img
                    src="@/assets/img/twitter-icon.svg"
                    alt="Twitter icon"
                    width="36"
                    height="36"
                  />
                </a>
                 <a :href="$t('instagramPath')" target="_blank" class="nav-item mr-4">
                  <img
                    src="@/assets/img/instagram-icon.svg"
                    alt="Twitter icon"
                    width="36"
                    height="36"
                  />
                  </a>
                  <a :href="$t('linkedinPath')" target="_blank" class="nav-item mr-4">
                  <img
                    src="@/assets/img/linkedin-icon.svg"
                    alt="Linkedin icon"
                    width="36"
                    height="36"
                  />
                </a>
                 <a :href="$t('youtubePath')" target="_blank" class="nav-item">
                  <img
                    src="@/assets/img/youtube-icon.svg"
                    alt="Twitter icon"
                    width="36"
                    height="36"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ContentService from "@/services/ContentService.js";


export default {
  data() {
    return {
      footerItems: [],
      footerUserData: {
        userName: '', 
        email: '',
        message: ''
      },
      pageSlug: this.$t('contactUsSlug'),
      
    };
  },
  created() {
     ContentService.getFooter(this.$application, this.$t("languageCode"))
      .then((res) => (this.footerItems = res.data.data.subDynamicFields))
      .catch((error) => console.log("There was an error:" + error));
  },

  methods: {
    submit() {
    },
    fetchData() {

    },
  },
};
</script>
