<template>
  <div id="app">
    <NavBar></NavBar>
    <MainSlider></MainSlider>
    <LinesOfActivity></LinesOfActivity>           
    <section class="section section-3 pb-0">
          <div class="container">
            <div class="row">
              <TrainingServices></TrainingServices>
              <News></News>
              <Survey></Survey>
            </div>
          </div>
    </section>
    <References></References>
    <Footer></Footer>
  </div>
</template>

<script>
  import NavBar from '@/components/shared/NavBar.vue';
  import MainSlider from '@/components/MainSlider.vue';
  import LinesOfActivity from '@/components/LinesOfActivity.vue';
  import TrainingServices from '@/components/TrainingServices.vue';
  import News from '@/components/News.vue';
  import Survey from '@/components/Survey.vue';
  import References from '@/components/References.vue';
  import Footer from '@/components/shared/Footer.vue';

  export default {
    name: 'App',
    components: {
      NavBar,
      MainSlider,
      LinesOfActivity,
      TrainingServices,
      News,
      Survey,
      References,
      Footer
    }
  }
</script>

<style>

</style>