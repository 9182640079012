import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


import VueResource from 'vue-resource'
import { routes } from './router/routes'
import { store } from './store/store';

import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/radio.css'

import moment from "moment";

import i18n from './i18n'
import VueHead from 'vue-head'
import Vuelidate from 'vuelidate'


Vue.use(Vuelidate)

Vue.use(VueHead)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


library.add(faUserSecret);
library.add(faChevronUp);
library.add(faChevronDown);


Vue.component("font-awesome-icon", FontAwesomeIcon);



Vue.use(VueResource);

Vue.use(VueRouter);
const router = new VueRouter({
 routes: routes,
 mode: 'history'
});

Vue.http.options.root = "https://vuejs-http-da57b.firebaseio.com/";


Vue.http.interceptors.push((request, next) => {
  console.log(request);
  if (request.method == 'POST') {
    request.method ='PUT';
  }
  next(response => {
    response.json = () => { return {messages: response.body} }
  });

});


Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.config.productionTip = false

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if(!language) {
    language = 'en-EN'
  }

  // set the current language for i18n
  i18n.locale = language
  next()
 
});


// Global Variables

Vue.prototype.$api_url = 'https://api.tavguvenlik.com';
Vue.prototype.$application = "5e5d10f0059b14822c6f256e";
Vue.prototype.$token = '3f1c945dda80e43cd5683c8443092787';
Vue.prototype.$apiClient = axios.create({
  baseURL: Vue.prototype.$api_url,
  withCredentials: false,
  headers: {
    'Authorization': 'Token ' + Vue.prototype.$token,
    'Content-Type': 'application/json'
  }
});




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


// Prevent Google Analytics to work in localhost
//////////////////////////////////////////////////////////////

// const is Local = document.location.hostname === 'localhost';
//const isDeveloper = document.cookie.indexOf('is_dev') > -1;

// if (isLocal || isDevelopment) {
// window['ga-disable-UA-134225660-1] = true;
//}
//
//