<template>
  <b-navbar toggleable="lg" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container overflow-visible">
      <div class="nav-item language d-flex align-items-center">
        <LanguageSwitcher></LanguageSwitcher>
        <a class="nav-link regular" target="_blank" :href="$t('holdingPath')">
          <img src="@/assets/img/holding-icon.svg" alt="Holding icon" width="12" height="12" />
          TAV Holding
        </a>
      </div>
      <b-navbar-brand class="navbar-brand">
        <router-link :to="`/${$i18n.locale}`" active-class="active" exact>
          <img :src="$t('logoPath')" :alt="$t('windowTitle.base')" width="83" height="50" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="main-nav navbar-collapse" is-nav>
        <b-navbar-nav class="navbar-nav ml-auto">
          <template v-for="navItem in navItems">
            <b-nav-item-dropdown
              :key="'dropdown-' + navItem._id"
              class="nav-item"
              v-if="navItem.subDynamicFields && navItem.subDynamicFields.length"
            >
              <template v-slot:button-content>
                <span>{{ navItem.value }}</span>
              </template>
              <li v-for="subNavItem in navItem.subDynamicFields" :key="subNavItem.slug">
                <router-link
                  :to="{
                    name: subNavItem.slug === 'haberler' ? 'PressRoomTR' : 'PageDetails',
                    params: { slug: navItem.slug, pageDetailSlug: subNavItem.slug }
                  }"
                  class="dropdown-item"
                >
                  {{ subNavItem.value }}
                </router-link>
              </li>
            </b-nav-item-dropdown>
            <b-nav-item :key="'link-' + navItem._id" v-else>
              <router-link :to="`${navItem.link}`" class="nav-item">
                {{ navItem.value }}
              </router-link>
            </b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import ContentService from "@/services/ContentService.js";
import LanguageSwitcher from "@/components/shared/LanguageSwitcher.vue";

export default {
  name: "NavBar",
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      navItems: [],
      pageSlug: this.$route.params.slug,
    };
  },
  created() {
    ContentService.getNavigation(this.$application, this.$t("languageCode"))
      .then((res) => (this.navItems = res.data.data.subDynamicFields))
      .catch((error) => console.log("There was an error:" + error));
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
