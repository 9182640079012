<template>
  <div>
    <div v-for="navItem in navItems" v-bind:key="navItem.slug">
      <div v-if="navItem.slug == pageSlug">
        
        <div class="sidebar">
           
           <span v-for="subNavItem of navItem.subDynamicFields"  v-bind:key="subNavItem.slug">
                  <router-link v-if="(subNavItem.slug == 'news')"
                     :to="{name: 'PressRoom', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>
                   <router-link v-else-if="(subNavItem.slug == 'haberler')"
                     :to="{name: 'PressRoomTR', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>
                   <router-link v-else-if="(subNavItem.slug == 'yayinlar')"
                     :to="{name: 'AnnouncementsTR', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>
                  <router-link v-else-if="(subNavItem.slug == 'publications')"
                     :to="{name: 'Announcements', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>

                  <router-link v-else-if="(subNavItem.slug == 'basinda-tav-guvenlik')"
                     :to="{name: 'TAVSecurityInPressTR', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>

                   <router-link v-else-if="(subNavItem.slug == 'tav-security-in-press')"
                     :to="{name: 'TAVSecurityInPress', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                   <a class="nav-link"> {{ subNavItem.value }}</a>
                  </router-link>

                  <router-link v-else-if="(subNavItem.slug == 'feedback-message-box')"
                     :to="{name: 'Feedback', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  ><a class="nav-link">{{ subNavItem.value }}</a></router-link>
                   <router-link v-else-if="(subNavItem.slug == 'gorus-ve-onerileriniz')"
                     :to="{name: 'FeedbackTR', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                    <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>

                  <router-link v-else-if="(subNavItem.slug == 'bize-ulasin')"
                     :to="{name: 'ContactUs', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                    <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>
                
                  <router-link v-else-if="(subNavItem.slug == 'job-application')"
                     :to="{name: 'JobApplication', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  ><a class="nav-link">{{ subNavItem.value }}</a></router-link>
                   <router-link v-else-if="(subNavItem.slug == 'is-basvuru-formu')"
                     :to="{name: 'JobApplicationTR', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                    <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>
                  <router-link v-else-if="(subNavItem.slug == 'movie')"
                     :to="{name: 'VideoGallery', params: {slug:navItem.slug} }" tag="li" active-class="active" class="sidebar-item">
                     <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>
                   <router-link v-else-if="(subNavItem.slug == 'tanitim-filmi')"
                     :to="{name: 'VideoGalleryTR', params: {slug:navItem.slug} }" tag="li" active-class="active" class="sidebar-item">
                    <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>
                  <router-link v-else-if="(subNavItem.slug == 'sikca-sorulan-sorular') || (subNavItem.slug == 'frequently-asked-questions')"
                     :to="{name: 'FrequentlyAsked', params: {slug:navItem.slug} }" 
                      
                     tag="li"
            active-class="active"
            class="sidebar-item"
                    
                  >
                    <a class="nav-link">{{ subNavItem.value }}</a>
                  </router-link>
          <router-link v-else
            :to="{ name: 'PageDetails', params: { pageDetailSlug: subNavItem.slug } }"
            tag="li"
            active-class="active"
            class="sidebar-item"
          >
            <a class="nav-link">{{ subNavItem.value }}</a>
          </router-link>
          </span>
        </div>
        <img
          :src="
            'https://webcmstavsecurity.tav.aero/uploads/5e5d10f0059b14822c6f256e/dynamic-fields/images/' +
              navItem.image
          "
          alt="Sidebar image"
          width="100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentService from "@/services/ContentService.js";
export default {
  data() {
    return {
      navItems: [],
      pageSlug: this.$route.params.slug
    };
  },
  created() {
    ContentService.getNavigation(this.$application, this.$t("languageCode"))
      .then((res) => (this.navItems = res.data.data.subDynamicFields))
      .catch((error) => console.log("There was an error:" + error));
  }

};
</script>
